.container {
  position: relative;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  margin-left: 20px;
  line-height: 1.5;

  .prism {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  &:before {
    content: '$';
    user-select: none;
    position: absolute;
    top: 0;
    left: -20px;
  }

  .input {
    color: transparent;
    caret-color: white;
    background: transparent;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    font: inherit;
    resize: none;
  }
}
