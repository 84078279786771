.typography {
  line-height: 1;
}

.h1 {
  margin: 0;
  font-size: 3em;
  font-weight: normal;
}

.h2 {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  font-weight: lighter;
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &,
  &:active {
    color: white;
  }
}
