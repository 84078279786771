import { default as default2 } from "classnames";
import { useState, useEffect, useRef } from "react";
import { j as jsxRuntimeExports } from "./wrap-DIyWHivQ.js";
import { w } from "./wrap-DIyWHivQ.js";
async function hash(algorithm, message, defaultValue) {
  if (!algorithm || !message)
    return defaultValue;
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest(algorithm, msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}
const subtleCrypto = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  hash
}, Symbol.toStringTag, { value: "Module" }));
function useCachedState(supplier, deps) {
  const [value, setValue] = useState(() => supplier(...deps));
  useEffect(() => setValue(() => supplier(...deps)), deps);
  return [value, setValue];
}
function useDidUpdateEffect(effect, deps) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      return effect();
    }
    didMountRef.current = true;
  }, deps);
}
function useSubtleCrypto(algorithm, value, defaultValue) {
  const [hashedValue, setHashedValue] = useState(defaultValue);
  useEffect(() => {
    hash(algorithm, value, defaultValue).then(setHashedValue);
  }, [algorithm, value]);
  return hashedValue;
}
function toArgs(props) {
  if (Array.isArray(props))
    return props;
  return [props];
}
function hooked(useHook) {
  return ({ hook }) => {
    const result = useHook(...toArgs(hook));
    if (typeof result === "undefined")
      return "<undefined>";
    if (typeof result === "object")
      return /* @__PURE__ */ jsxRuntimeExports.jsx("div", { children: JSON.stringify(result) });
    return result.toString();
  };
}
export {
  default2 as classNames,
  subtleCrypto as crypto,
  hooked,
  useCachedState,
  useDidUpdateEffect,
  useSubtleCrypto,
  w as wrap
};
